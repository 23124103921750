import React from 'react';
import './style.scss';


const PrivateSection = () => {
  return (
    <section id="Policy">
      <div className="container">
        <div className="row">
          <div className="Policy">
            <h3>개인정보 취급 방침</h3>
            <br/>
            <p>멤버십 솔루션 "CVVE Cloud"의 운영사 디지엠유닛원 주식회사(이하 '회사'라 합니다.)은 이용자의 개인정보를 중요시하며, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 , 「 개인정보보호법」 을 준수하기 위하여 노력하고 있습니다. 회사는 개인정보처리방침을 통하여 회사가 이용자로부터 제공받은 개인정보가 어떠한 용도와 방식으로 이용하고 있으며, 개인정보보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다. 본 방침은 공고일부터 시행되며, 이를 개정하는 경우 공지사항 (또는 • 서면 • 이메일 • 전화 • SMS 등의 방법으로 개별공지)을 통하여 공지됩니다.</p>
            <br/>
            <p>
              1. 총칙<br/> 2. 수집하는 개인정보의 항목 및 수집방법<br/> 3. 개인정보 수집에 의한 동의<br/> 4. 개인정보의 수집목적 및 이용 목적<br/> 5. 쿠키에 의한 개인정보 수집<br/> 6. 개인정보 제 3자 제공<br/> 7. 개인정보의 열람 및 정정<br/> 8. 개인정보 수집, 이용, 제공에 대한 동의철회 (회원탈퇴)<br/> 9. 개인정보의 보유기간 및 이용기간, 파기절차 , 파기방법<br/> 10. 개인정보보호를
              위한 기술 및 관리적 대책<br/> 11. 링크사이트<br/> 12. 게시물<br/> 13. 개인정보의 위탁처리<br/> 14. 이용자의 권리와 의무<br/> 15. 의견수렴 및 불만처리<br/> 16. 정보관리책임자 및 담당자<br/> 17. 광고성 정보전송<br/> 18. 개인정보 자동 수집 장치 설치 / 운영 및 거부에 대한 사항<br/> 19. 정책 변경에 따른 공지의무<br/>
            </p>
            <br/>
            <br/>
            <p class="lead">1. 총칙</p>
            <p>"개인정보" 란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명 , 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 회사는 이용자의 개인정보보호를 매우 중요시하며, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 , 「 개인정보보호법」 등을 준수하고 있습니다. 회사는 본 개인정보처리방침을
              통하여 이용자께서 제공하시는 개인정보가 어떠한 용도의 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다. 회사는 본 개인정보처리방침을 CVVE Cloud 홈페이지 및 앱 내에 공개함으로써 이용자께서 언제나 용이하게 보실 수 있습니다. 회사는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고 개인정보처리방침을 개정하는 경우 버전번호 등을 부여하여 개정된
              사항을 쉽게 알아볼 수 있도록 하고 있습니다.
            </p>
            <br/>
            <p class="lead">
              2. 수집하는 개인정보의 항목 및 수집방법</p>
            <p>
              회사는 최초 회원 가입 또는 서비스 이용 시 이용자로부터 아래와 같은 개인정보를 수집하고 있습니다. 개인정보처리방침과 CVVE Cloud 내 개인정보 수집 • 이용 동의와 상충되는 부분이 있다면 CVVE Cloud 홈페이지 내 개인정보 수집 • 이용 동의서의 내용이 우선합니다. (※ 귀하께서는 개인정보 수집 • 이용에 대한 동의를 거부하실 수 있으나 이는 서비스 제 에 필수적으로 제공되어야 하는 정보이므로 동의를 거부하실 경우 본 CVVE Cloud 서비스를 사용하실
              수 없습니다.)
              <br/> 가. 회사는 회원가입 시 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집합니다.<br/> 나. 이용자가 당사의 서비스를 이용하기 위해서는 회원가입 시 개인정보를 입력하셔야 합니다.<br/>
              <br/> 1) 수집항목(개인) : 이름, 생년월일, 성별, 아이디(고유식별자), 비밀번호, 휴대전화번호<br/> 2) 자동수집항목 : 서비스 이용기록, 접속 로그, 쿠키 , 접속 IP 정보<br/> 3) 그 외에 이용자가 유료 컨텐츠 및 일부 컨텐츠를 이용하는 경우 다음과 같은 결제를 위한 추가정보를 요구할 수 있습니다.<br/>
              <br/> ① 결제방법에 따라<br/>
              <br/> - 카드결제의 경우 : 신용카드 종류, 카드번호, 유효기간 등 (벤사의 암호화 코드 수집 외 미수집 )<br/> - 휴대폰 결제의 경우 : 휴대폰 번호, 통신사 등<br/> - 은행입금 결제의 경우 : 거래은행명, 계좌번호, 거래자성명 등<br/> - 기타 결제방법 별로 결제에 필요한 필수 정보<br/>
              <br/> ② 상품배송에 필요한 정보로서 보내는 사람과 받는 사람의 성명 , 주소, 연락처 등<br/>
              <br/> 다. 개인정보 수집방법
              <br/> 회사는 다음과 같은 방법으로 개인정보를 수집 합니다<br/> - 홈페이지 회원가입, 회원정보수정, 팩스, 전화, 고객센터 문의하기, 이메일, 이벤트 응모, 배송요청, 카카오톡과 같은 플랫폼 API 연동<br/> - 이용자가 서비스 이용 과정에서 직접 입력, 서비스 이용 시 기기로부터 측정되거나 자동생성<br/> 파트너 서비스로부터 추가 수집, 서비스 실행 시 자동 수집합니다.<br/>
            </p>
            <br/>
            <p class="lead">
              3. 개인정보 수집에 의한 동의</p>
            <p>회사는 회원의 개인정보 수집에 대한 동의를 받고 있으며, 개인정보취급방침 또는 이용약관에 대한 동의절차를 마련하고 있습니다. 「동의합니다」 버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다. 「동의하지 않음」 선택 시 CVVE Cloud 서비스를 받으실 수 없습니다.
              <br/>
            </p>
            <br/>
            <p class="lead">4. 개인정보의 수집목적 및 이용목적</p>
            <p>
              가. 회사는 회원에게 서비스를 제공하기 위하여 필요한 개인정보를 수집, 이용하고 있습니다. 수집하는 개인정보 항목에 따른 구체적인 수집목적 및 이용목적은 다음과 같습니다.<br/>
              <br/> ① 이름 : 이용자의 실명확인<br/> ② 아이디 : 서비스이용을 위한 식별자<br/> ③ 비밀번호 : 서비스이용을 위한 본인확인, 아이디 도용의 방지<br/> ④ 휴대폰 : 이용자에 대한 고지사항 전달 , 이용자의 불만사항 수렴을 위한 의사소통 경로의 확보, 서비스품질에 대한 의견 청취, 신제품 및 서비스 안내 TM, 제휴를 통한 서비스 및 제품 안내 TM, 서비스 이용에 대한 이용자 의사의 수렴, 각종 이벤트 혜택을 위한
              안내, 아이디/비밀번호 분실 시 아이디 찾기 및 신규비밀번호 발급<br/>⑥ 주소 : 유료서비스 이용 시 내역발송 , 사은품/경품 /이벤트 상품/
              쇼핑물품 등의 배송, 고지사항 전달 , 지역정보 및 지역광고 서비스제공<br/> ⑦ 은행계좌 정보 , 카드정보, 휴대폰번호, 전화번호 : 유료 서비스 이용에 대한 요금 결제<br/>
              <br/> 나. 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보는 수집하지 않습니다.<br/>
              <br/>
            </p>
            <br/>

            <p class="lead">
              5. 쿠키에 의한 개인정보 수집</p>
            <p>가. 회사는 회원의 정보를 저장하고 수시로 찾아내는 쿠키(cookie) 를 사용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 브라우저로 전송하는 소량의 정보를 말합니다.<br/> 나. 이용자의 웹사이트에 접속을 하면 회사의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 이용자의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는
              않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저에서 [도구] - [인터넷옵션] - [보안] - [사용자 정의수준] 을 선택함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 모든 쿠키의 저장을 거부하겠다고 선택하면, 쿠키를 통해 당사에서 제공하는 서비스가 있을 경우, 이를 제한적으로 이용하실 수 있습니다.<br/> 다. 회사는 이용자의 편의를
              위하여 쿠키를 운영합니다. 회사가 쿠키를 통해 수집하는 정보는 당사회원 ID 에 한하며, 그 외의 다른 정보는 수집하지 않습니다. 당사가 쿠키(cookie) 를 통해 수집한 회원 ID 는 다음의 목적을 위해 사용됩니다.<br/>
              <br/> ① 개인의 관심 분야에 따라 차별화된 정보를 제공<br/> ② 회원과 비회원의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟 (target) 마케팅에 활용<br/> ③ 이용한 종목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 이용할 때 개인 맞춤 서비스를 제공<br/> ④ 회원들의 습관을 분석하여 서비스 개편 등의 척도<br/>
            </p>
            <br/>
            <p class="lead">6. 개인정보 제 3 자 제공</p>
            <p>회사는 이용자들의 개인정보를 "4. 개인정보의 수집목적 및 이용목적" 에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br/>
              <br/> 1) 이용자들이 사전에 공개에 동의한 경우<br/> - 각종 서비스 이용 혹은 이벤트응모 , 사용자 리서치 등 여러 프로모션에 참여하시면서 개인정보 제 3 자 제공에 대해 동의한 경우입니다.<br/> - 이 경우도 반드시 고지 및 동의 받은 목적과 기간 내에서만 이용되며 , 본 개인정보취급방침에 명기한 사항에 따라 처리됩니다.<br/> 2) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우<br/> 3)
              정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br/> 4) 다음 각 호의 어느 하나에 해당하는 경우에는 정보주체 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를 제외하고는 이용자의 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 수 있습니다.<br/>
              <br/> ① 정보주체로부터 별도의 동의를 받은 경우<br/> ② 다른 법률에 특별한 규정이 있는 경우<br/> ③ 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제 3 자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br/> ④ 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로
              개인정보를 제공하는 경우<br/> ⑤ 개인정보를 목적 외의 용도로 이용하거나 이를 제3 자에게 제공하지 아니하면 다른 법률에서 정하는 소관업무를 수행할 수 없는 경우로서 보호위원회의 심의· 의결을 거친 경우<br/> ⑥ 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우<br/> ⑦ 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우<br/> ⑧ 법원의 재판업무 수행을 위하여 필요한 경우<br/>      ⑨ 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우<br/>
            </p>
            <br/>
            <p class="lead">7. 개인정보의 열람 및 정정</p>
            <p>
              가. 이용자는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나 수정하실 수 있으며, 사이트에서 로그인 과정을 거친 후 수정이 가능합니다. 다만, 아이디는 유일한 개인을 위한 기준으로 사용하므로 수정을 하실 수 없습니다.<br/> 나. 개인정보를 열람, 수정 및 삭제 하고자 할 경우에는 홈페이지 상단의 ' 마이페이지' 에서 개인정보의 열람 및 수정을 직접 하실 수 있습니다. 직접 열람이나 수정을 할 수 없을 경우, 개인정보관리책임자에게
              전화나, 서면, 이메일로 연락하시면 즉시 조치를 취합니다.<br/> 다. 이용자가 직접 개인정보 오류의 정정을 할 경우 이는 즉시 반영되며, 오류의 정정이 불가하거나 지체되는 사유가 발생할 경우 이용자에게 사유를 전달하고 필요한 조치가 취해질 때까지 해당 정보는 노출되지 않도록 조치를 합니다.<br/> 라. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한
              잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3 자에게 지체 없이 통지하여 정정이 이루어지도록 합니다.<br/>
            </p>
            <p class="lead">8. 개인정보 수집, 이용, 제공에 대한 동의철회 (회원탈퇴)</p>
            <p>
              가. 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 동의한 내용을 이용자는 언제든지 철회할 수 있습니다. 동의철회( 회원탈퇴) 는 홈페이지 상단의 마이페이지를 이용하거나 개인정보관리책임자에게 서면, 전화, E-mail 등으로 연락하시면 즉시 개인정보의 삭제 등 필요한 조치를 취합니다. 당사에서 동의 철회를 하거나 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을 이용자에게 지체 없이 통지합니다.<br/> 나. 당사는
              개인정보의 수집에 대한 동의철회(회원탈퇴) 를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취합니다.<br/> 다. 이전에 동의한 취급위탁 및 3 자 제공에 대해 철회를 요청하실 경우, 고객센터를 통해서 온라인 또는 전화로 접수하시면 즉시 조치를 합니다<br/>
            </p>
            <br/>
            <p class="lead">9. 개인정보의 보유기간 및 이용기간 , 파기절차, 파기방법</p>
            <p>가. 이용자가 당사의 회원으로서의 자격을 유지하고 있는 동안에는 이용자가 회사에 제공한 개인정보를 계속 보유합니다.<br/> 나. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. 불건전한 서비스 이용으로 서비스에 물의를 일으킨 회원의 경우
              사법기관 수사의뢰 및 서비스 계약을 해지한 고객의 서비스 이용신청에 대한 승낙을 유보하고, 다른 회원을 보호할 목적으로 회원탈퇴 이후에도 해당 개인정보를 1년간 보유할 수 있습니다.<br/> 다. 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.<br/>
              <br/> ① 웹사이트 방문기록 : 3개월 (통신비밀보호법)<br/> ② 본인확인에 관한 기록 : 6개월 (정보통신망 이용촉진 및 정보보호 등에 관한 법률)<br/> ③ 표시/ 광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)<br/> ④ 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)<br/> ⑤ 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에
              관한 법률)<br/> ⑥ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)<br/> ⑦ 전자금융 거래에 관한 기록 : 5년 (전자금융거래법)<br/>
              <br/> 라. 회원이 본인이 당사의 회원임을 증빙하기 위해 회사에 발송한 신분증 사본 등 서류일체는 본인확인 후 즉시 파기합니다.<br/> 마. 귀하의 동의를 받아 보유하고 있는 거래정보 등을 귀하께서 열람을 요구하는 경우 당사는 지체 없이 그 열람 확인 할 수 있도록 조치합니다.<br/> 바. 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.<br/>
              <br/> ① 파기절차<br/> - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 (종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.<br/> - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br/> ② 파기방법<br/> - 종이에 출력된 개인정보는 분쇄기로
              분쇄하거나 소각을 통하여 파기합니다.<br/> - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
              <br/>
            </p>
            <br/>
            <p class="lead">10. 개인정보보호를 위한 기술 및 관리적 대책</p>
            <p>
              당사는 회원의 개인정보 수집에 대한 동의를 받고 있으며 , 개인정보취급방침 또는 이용약관에 대한 동의절차를 마련하여 「동의합니다」 버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.<br/>
              <br/> 가. 기술적 대책<br/>
              <br/> ① 귀하의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능 (Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.<br/> ② 당사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고
              있습니다.<br/> ③ 당사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치 (SSL)를 채택하고 있습니다.<br/> ④ 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.<br/>
              <br/> 나. 관리적 대책<br/>
              <br/> ① 당사는 귀하의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.<br/> - 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자<br/> - 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자<br/> - 기타 업무상 개인정보의 취급이 불가피한 자<br/> ② 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에
              관해 정기적인 사내 교육 및 외부 위탁 교육을 실시하고 있습니다.<br/> ③ 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보취급방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.<br/> ④ 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.<br/> ⑤ 개인정보와
              일반 데이터를 혼합하여 보관하지 않고 별도의 서버를 통해 분리하여 보관하고 있습니다.<br/> ⑥ 당사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID 와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.<br/> ⑦ 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이
              유발될 경우 당사는 즉각 귀하께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.<br/>
              <br/>
            </p>
            <br/>
            <p class="lead">11. 링크사이트</p>
            <p>당사는 귀하께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 당사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 당사가 포함하고 있는 링크를 클릭 (click)하여 타 사이트 (site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보취급방침은 당사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.</p>
            <br/>
            <p class="lead">12. 게시물</p>
            <p>가. 당사는 귀하의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지 아니합니다.<br/>
              <br/> ① 스팸(spam) 성 게시물<br/> ② 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글<br/> ③ 동의 없는 타인의 신상공개와 저작권 , 제3자의 저작권 등 권리를 침해하는 내용 , 기타 게시판 주제와 다른 내용의 게시물<br/> ④ 당사는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는 타인의 신상 공개시 특정부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다.<br/> ⑤ 다른 주제의
              게시판으로 이동 가능한 내용일 경우 해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다.<br/> ⑥ 그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.<br/>
              <br/> 나. 근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보 공개 전에 심사숙고 하시기 바랍니다.<br/>
              <br/>
            </p>
            <br/>
            <p class="lead">13. 개인정보의 위탁처리</p>
            <p>가. 회사는 원활하고 향상된 서비스를 위하여 개인정보 취급을 타인에게 위탁할 수 있습니다.<br/> 나. 회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 또한 위탁처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다. 이용자의 개인정보 위탁 처리 시 수탁업체 및 위탁 목적은 아래와 같습니다.<br/>
              <br/>
              [수탁업체 위탁목적 및 업무내용]<br/>
                <br/>㈜한국정보통신 KICC<br/> 신용카드 결제, 실시간계좌이체 결제 대행, 휴대폰 결제 대행<br/>
                <br/>코리아크레딧뷰로㈜ KCB<br/> 아이핀 회원 인증 , 휴대폰 본인 확인<br/>
                <br/>한국신용정보㈜<br/> 계좌정보 검증<br/>
                <br/>
            </p>
            <br/>
            <p class="lead">14. 이용자의 권리와 의무</p>
            <p>가. 귀하의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생 하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.<br/> 나. 귀하는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 귀하의 개인정보가 유출되지 않도록 조심하시고
              게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『 정보통신망이용촉진 및 정보보호 등에 관한 법률 』 등에 의해 처벌받을 수 있습니다.<br/>
            </p>
            <br/>
            <p class="lead">15. 의견수렴 및 불만처리</p>
            <p>가. 당사는 귀하의 의견을 소중하게 생각하며 , 귀하는 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.<br/> 나. 당사는 귀하와의 원활환 의사소통을 위해 민원처리센터를 운영하고 있습니다.<br/> 다. 전화 상담은 평일 오전 10:00 ~ 오후 05:00 에만 가능합니다.<br/> 라. 전자우편이나 팩스 및 우편을 이용한 상담은 접수 후 24 시간 내에 성실하게 답변 드리겠습니다. 다만 근무시간 이후 또는 주말 및 공휴일에는
              익일 처리하는 것을 원칙으로 합니다.<br/> 마. 기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터로 문의하실 수 있습니다.<br/>
              <br/> ① 개인분쟁조정위원회 (www.1336.or.kr / 1336)<br/> ② 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)<br/> ③ 대검찰청 첨단범죄수사과 (http://www.spo.go.kr / 02-3480-2000)<br/> ④ 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)<br/>
              <br/>
            </p>
            <br/>
            <p class="lead">16. 정보관리책임자 및 담당자</p>
            <p>
              가. 귀하가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다.<br/> 나. 개인정보를 보호하는데 있어 귀하께 고지한 사항들에 반하는 사고가 발생할 시에 개인정보관리책임자가 모든 책임을 집니다.<br/> 다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 귀하의
              개인정보를 취급하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.<br/>
              <br/>
            </p>
            <br/>
            <p class="lead">17. 광고성 정보전송</p>
            <p>가. 당사는 귀하의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다.<br/> 나. 당사는 귀하가 뉴스레터 등 전자우편 전송에 대한 동의를 한 경우 전자우편의 제목 및 본문 란에 다음 사항과 같이 귀하가 쉽게 알아 볼 수 있도록 조치합니다.<br/> ① 전자우편의 제목 : (광고) 라는 문구를 제목 란에 표시하지 않을 수 있으며 전자우편 본문 란의 주요 내용을 표시합니다.<br/> ② 전자우편의 본문 :<br/>      - 귀하가 수신거부의 의사표시를 할 수 있는 전송자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다.<br/> - 귀하가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및 영문으로 각각 명시합니다.<br/> - 귀하가 동의를 한 시기 및 내용을 명시합니다.<br/> 다. 팩스 ㆍ 휴대폰 문자전송 등 전자우편 이외의 문자전송을 통해 영리목적의 광고성 정보를 전송하는 경우에는 전송내용 처음에 “(광고)” 라는 문구를 표기하고
              전송내용 중에 전송자의 연락처를 명시하도록 조치합니다.<br/>
            </p>
            <br/>
            <p class="lead">18. 개인정보 자동 수집 장치 설치 / 운영 및 거부에 대한 사항</p>
            <p>가. 당사는 이용자에게 관심기반 맞춤형 광고 제공을 위해 “ 쿠키 ” 기술을 사용하고 있으며 이 때 사용되는 쿠키에는 개인을 식별하는 어떠한 정보도 포함되어 있지 않습니다.<br/> 나. 이용자는 언제든지 관심기반 맞춤형 광고 수신을 거부할 수 있습니다.<br/>
            </p>
            <br/>
            <p class="lead">19. 정책 변경에 따른 공지의무</p>
            <p>가. 개인정보취급방침은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 개정 최소 7 일 전부터 홈페이지의 '공지' 란을 통해 고지할 것입니다.<br/> 나. 당사는 수집한 개인정보의 기본적 동의사항 (수집 및 이용 목적, 수집항목, 보유 및 이용 기간, 동의거부권리) 에 중대한 변경사항이 있을 경우, 별도로 이용자의 동의를 받을 것입니다.<br/>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivateSection;
