import React from 'react';
import PrivateSection from '@components/Policy/section/PrivateSection';
import Layout from '@layouts/Layout';

const Private = () => {
  return (
    <Layout>
      <PrivateSection />
    </Layout>
  );
};

export default Private;